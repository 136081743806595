<template>
  <div>
    <v-container class="fill-height">
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item dense @click="deleteMessage">
            <v-list-item-title>{{
              $t('messages.deleteMessage')
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row class="fill-height pb-14" align="end">
        <v-col class="messagesWindow">
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-row align-center my-2',
              item.createdBy == user.id ? 'justify-end' : null,
            ]"
            @contextmenu="show($event, item.id)"
          >
            <span v-if="item.createdBy == user.id" class="blue--text mr-3">{{
              item.message
            }}</span>
            <v-avatar
              :color="item.createdBy == user.id ? 'indigo' : 'red'"
              size="36"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="white--text">{{
                    getInitials(item.author)
                  }}</span>
                </template>
                <span>{{ getFullName(item.author) }}</span>
              </v-tooltip>
            </v-avatar>
            <span v-if="item.createdBy != user.id" class="blue--text ml-3">{{
              item.message
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-center">
            <v-text-field
              v-model="msg"
              :placeholder="$t('messages.typeSomething')"
              @keypress.enter="send"
            ></v-text-field>
            <v-btn icon class="ml-4" @click="send"
              ><v-icon>mdi-send</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//TODO: Need to get posted Username with every message, preferably Full Name. Posting should also track user.
import { mapState } from 'vuex'
export default {
  data: function () {
    return {
      chat: [],
      selectedMessage: '',
      msg: null,
      showMenu: false,
      x: 0,
      y: 0,
    }
  },
  created() {
    this.$store.dispatch('message/getMessages', this.$route.params.id)
  },
  methods: {
    send: function () {
      let payload = {
        claimId: this.$route.params.id,
        message: this.msg,
      }
      this.$store
        .dispatch('message/sendMessage', payload)
        .then((this.msg = null))
    },
    show(e, id) {
      if (this.isAdmin) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
          this.selectedMessageId = id
        })
      }
    },
    deleteMessage() {
      this.$store.dispatch('message/deleteMessage', this.selectedMessageId)
    },
    getInitials(item) {
      return item.firstName[0] + item.lastName[0]
    },
    getFullName(item) {
      return `${item.firstName} ${item.lastName}`
    },
  },
  computed: {
    ...mapState({
      messages: (state) => state.message.messages,
      user: (state) => state.user.user,
    }),
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
  },
  watch: {
    messages() {
      this.chat = this.messages
    },
  },
  destroyed() {
    this.$store.dispatch('message/clearMessages')
  },
}
</script>

<style lang="scss" scoped>
.messagesWindow {
  overflow-y: scroll;
  height: 400px;
}
</style>
